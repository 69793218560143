<template>
  <section
    ref="popLogout"
    id="pop__logout"
    class="pop pop--full pop__login"
    @click="close('close')"
  >
    <div class="pop__body">
      <a class="close" @click="close('close')">
        <img src="@/assets/icon/cross.svg"/>
      </a>
      <div class="top">
        <img v-if="profileImage" class="top__profile" :src="profileImage" />
        <div class="top__content">
          <span class="top__title"> {{ userName }}</span>
          <span class="top__description"> {{ $t("DDPAY Member") }} </span>
        </div>
      </div>
      <div class="middle">
        <a
          class="login__button login__button--secondary"
          @click="logout"
        >
          <img class="login__icon" src="@/assets/icon/logout.svg" />
          <span class="login__text"> {{ $t("Member logout") }} </span>
        </a>
      </div>
      <!-- <span class="message">訊息</span> -->
      <div class="bottom" v-html="serviceProvider">
        <!-- <span class="bottom__text">服務由</span>
        <img class="bottom__logo" src="@/assets/icon/ddpayW.svg" />
        <span class="bottom__text">提供</span> -->
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex';

export default {
  name: "PopupLogout",
  props: {},
  components: {},
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user
    }),
    ...mapGetters({
      userName: 'getUserName',
    }),
    profileImage () {
      return this.user?.user?.picture;
    },
    serviceProvider () {
      return this.$i18n.t('Service provider');
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.popup();
  },
  methods: {
    close(eventName) {
      const self = this;
      const pop = this.$refs.popLogout; // document.getElementById('popError');
      pop.children[0].style.animationName = "popdown";

      setTimeout(function () {
        document.querySelector("body").setAttribute("style", "overflow:auto");
        self.$emit("close", eventName);
        self.$store.commit('updateShowLogout', false)
      }, 250);
    },
    popup() {
      const target = this.$refs.popLogout;
      target.style.display = "flex";
      document.querySelector("body").setAttribute("style", "overflow:hidden");
    },
    logout() {
      const token = this.$route.params.token;
      // 如果有動態參數會有問題
      const redirectUrl = window.location.origin + '/orders';
      console.log("[popupLogout] start logout", redirectUrl);

      this.signout(token)
    },
  },
};
</script>
